import React from 'react';
import { useFetchRepos } from 'hooks';
import './style.css';

const getDescription = (release) => {
  if (release.type === 'milestone') {
    return (
      <div className="milestone">
        <a target="_blank" rel="noopener noreferrer" href={release.html_url} className="milestone-title">
          {`${release.displayname} `}
        </a>
        <p className="milestone-description">
          {release.description.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
          })}
        </p>
      </div>
    );
  }
  return (
    <p className="release-version">
      {`${release.displayname} ${release.version}`}
    </p>
  );
};

const Home = () => {
  const { loading, repos, error } = useFetchRepos();
  if (loading) {
    return (
      <div className="loader">
        <img src="download.gif" alt="" />
      </div>
    );
  }
  if (!loading && error) {
    return (<p className="error">{ error }</p>);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <ul className="timeline">
            {repos.map((repo, index) => (
              <li className="timeline-date" key={index}>
                <p className="date">{repo.timelineDate}</p>
                <ul className="releases">
                  { repo.releases.map((release, index) => (
                    <li className="release" key={index}>
                      {getDescription(release)}
                      <ul className="release-issues">
                        { release.issues && release.issues.map((issue, index) => (
                          issue.assignee &&
                          <li className="release-issue" key={index}>
                            <a target="_blank" rel="noopener noreferrer" href={issue.html_url}>
                              <img src={issue.assignee && issue.assignee.avatar_url} alt="" />
                              {issue.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Home;
