import { useEffect, useReducer } from 'react';
import { github, zenhub } from 'api';
import { getMachineDate, getSimpleDate } from 'helpers';
import allRepoIds from 'repos.json';

const useFetchRepos = () => {
  const [{ repos, loading, error }, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, error: null, repos: [] },
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allReleases = [];
        await Promise.all(allRepoIds.map((eachRepo) => zenhub
          .getReleases(eachRepo.id)
          .then((response) => {
            if (response.length !== 0) {
              response.forEach((release) => {
                if (release.state === 'open' && !allReleases.find((r) => r.release_id === release.release_id)) {
                  allReleases.push({
                    name: eachRepo.name,
                    displayname: eachRepo.displayname,
                    id: eachRepo.id,
                    release_id: release.release_id,
                    desired_end_date: release.desired_end_date,
                    title: release.title,
                    issues: [],
                  });
                }
              });
            }
          })));

        const allReleasesWithIssues = [];
        await Promise.all(allReleases.map((eachRepo) => zenhub
          .getReleseIssues(eachRepo.release_id)
          .then((response) => {
            response.forEach((item) => {
              allReleasesWithIssues.push({
                name: eachRepo.name,
                displayname: eachRepo.displayname,
                id: eachRepo.id,
                release_id: eachRepo.release_id,
                desired_end_date: eachRepo.desired_end_date,
                title: eachRepo.title,
                issues: item.issue_number,
              });
            });
          })));

        await Promise.all(allReleasesWithIssues.map((repodtl) => github
          .getRepoDetail(repodtl.name, repodtl.issues)
          .then((repoinfo) => {
            repodtl.issueDtl = repoinfo;
          })));

        await Promise.all(allRepoIds.map((eachRepo) => github
          .getRepoMilestones(eachRepo.name)
          .then((milestones) => {
            milestones.forEach((milestone) => {
              if (milestone.state === 'open') {
                allReleasesWithIssues.push({
                  displayname: milestone.title,
                  description: milestone.description,
                  desired_end_date: milestone.due_on,
                  name: eachRepo.name,
                  html_url: milestone.html_url,
                  type: 'milestone',
                });
              }
            });
          })));
        const formattedData = [];

        allReleasesWithIssues.forEach((item, i) => {
          const issueDetail = item.issueDtl;
          if (issueDetail) {
            if ((issueDetail.pull_request || issueDetail.labels.find((label) => label.name === 'Epic'))) {
              return;
            }
          }
          const newFormat = {
            timelineDate: getSimpleDate(item.desired_end_date),
            machineTime: getMachineDate(item.desired_end_date),
            date: item.desired_end_date,
            release: item.name,
            displayname: item.displayname,
          };

          if (item.type === 'milestone') {
            newFormat.description = item.description;
            newFormat.type = item.type;
            newFormat.html_url = item.html_url;
          } else {
            newFormat.releases = [];
            newFormat.repoid = item.id;
            newFormat.releaseid = item.release_id;
            newFormat.version = item.title;
            newFormat.issues = [];
            if (item.issueDtl !== null && item.issueDtl !== undefined) {
              newFormat.issues.push(item.issueDtl);
            }
          }
          formattedData.push(newFormat);
        });

        const categorizeIssuesByRelease = [];
        formattedData.forEach((item, i) => {
          const foundItem = categorizeIssuesByRelease
            .find((ele) => ele.release === item.release && ele.version === item.version);
          if (item.type === 'milestone' || !foundItem) {
            categorizeIssuesByRelease.push(item);
          } else if (foundItem) {
            if (item.issues[0]) foundItem.issues.push(item.issues[0]);
          }
        });

        const categorizeReleasesByDate = [];
        categorizeIssuesByRelease.forEach((item, i) => {
          const releaseObj = {
            release: item.release,
            repoid: item.repoid,
            releaseid: item.releaseid,
            version: item.version,
            displayname: item.displayname,
            issues: item.issues,
            type: item.type,
            description: item.description,
            html_url: item.html_url,
          };

          const foundItem = categorizeReleasesByDate
            .find((d) => d.machineTime === item.machineTime);
          if (foundItem) {
            if (item.type === 'milestone') {
              foundItem.releases.unshift(releaseObj);
            } else {
              foundItem.releases.push(releaseObj);
            }
          } else {
            const newFormat = {
              timelineDate: item.timelineDate,
              machineTime: item.machineTime,
              date: item.date,
              releases: [releaseObj],
            };
            categorizeReleasesByDate.push(newFormat);
          }
        });

        categorizeReleasesByDate.sort(
          (a, b) => Number(new Date(a.date)) - Number(new Date(b.date)),
        );
        setState({ loading: false, repos: categorizeReleasesByDate, error: null });
      } catch (e) {
        setState({
          loading: false,
          repos: [],
          error: 'Error while fetching data. Make sure you have valid access tokens and you have supplied them in query as /?zenhub="Token"&github="Token"',
        });
      }
    };
    fetchData();
  }, []);


  return {
    repos,
    loading,
    error,
  };
};

export default useFetchRepos;
