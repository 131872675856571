import axios from 'axios';
import queryString from 'query-string';

const owner = process.env.REACT_APP_GIT_OWNER;
let zenhubApiToken = null;
let githubApiToken = null;

if (process.env.NODE_ENV === 'development') {
  // in development mode use tokens from .env file
  zenhubApiToken = process.env.REACT_APP_ZENHUB_TOKEN;
  githubApiToken = process.env.REACT_APP_GITHUB_TOKEN;
} else {
  // in production mode use tokens form query string
  const { github, zenhub } = queryString.parse(window.location.search);
  zenhubApiToken = zenhub;
  githubApiToken = github;
}

/* zenhub configs */
const zenhubInstance = axios.create({
  baseURL: 'https://api.zenhub.com/p1/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-Authentication-Token': zenhubApiToken,
  },
});

zenhubInstance
  .interceptors
  .response
  .use((response) => response.data, (error) => {
    if (error.response.status === 404) {
      return null;
    }
    return Promise.reject(error);
  });

const zenhub = {
  getReleseIssues: (releaseId) => zenhubInstance.get(`reports/release/${releaseId}/issues`),
  getReleases: (repoId) => zenhubInstance.get(`repositories/${repoId}/reports/releases`),
};


/* github configs */
const githubInstance = axios.create({
  baseURL: 'https://api.github.com/',
  headers: {
    Authorization: `Bearer ${githubApiToken}`,
    'Content-Type': 'application/json',
  },
});

githubInstance
  .interceptors
  .response
  .use((response) => response.data, (error) => {
    if (error.response.status === 404) {
      return null;
    }
    return Promise.reject(error);
  });

const github = {
  getRepoDetail: (repoName, issue) => githubInstance.get(`repos/${owner}/${repoName}/issues/${issue}`),
  getRepoMilestones: (repoName) => githubInstance.get(`repos/${owner}/${repoName}/milestones`),
};


export {
  zenhub,
  github,
};
